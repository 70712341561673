
export const blog1 = {

    okvirno: 'Med je gotovo eno izmed tistih zdravilnih živil, na katerega v domači lekarni nikakor ne smemo pozabiti, še zlasti v času epidemije.', 

    naslov1: 'ZDRAVILNI UČINKI MEDU',
    prviOdstavek: 'Med je gotovo eno izmed tistih zdravilnih živil, na katerega v domači lekarni nikakor ne smemo pozabiti, še zlasti v času epidemije. Med krepi naš imunski sistem, uničuje bakterije in preprečuje njihovo razmnoževanje, kot vir antioksidantov preprečuje nastanek obolenj, zaradi vsebnosti sladkorja je idealen vir energije, zaradi mineralnih snovi pa velja za odlično pomirjevalo in naravno uspavalo. Med blagodejno vpliva na odpornost in izboljšuje koncentracijo, razstruplja naše telo in nase veže proste radikale, ki so sicer nevarni pri nastanku rakavih obolenj. Med je tudi odlično razkužilo in se ga lahko uporablja na ranah in drugih poškodbah. Z medom lahko pozdravimo celo mozoljavost.',
    drugiOdstavek: 'V zimskih mesecih med najpogosteje uživamo kot dodatek k čaju, pri čemer pa moramo paziti, da ga ne dodajamo v vrel napitek, temveč počakamo, da se čaj ohladi na sobno temperaturo. Tako med ne bo izgubil svojih zdravilnih učinkov. Če niste ljubitelj čaja, pa si lahko pripravite zdravilen in krepčilen napitek iz soka ene ali dveh limon, katerega razredčimo z mlačno vodo ter dodamo žlico ali dve medu. Sladkana tekočina spodbudi nastajanje sline, ki pomaga zdraviti suho in vzdraženo grlo. Med dokazano pomirja kašelj in lajša izkašljevanje.',

    naslov2: 'Katere vrste medu uporabiti za posamezne zdravstvene težave?',
    tretjiOdstavek: 'Lipov med - zaradi izrazitega antibakterijskega delovanje je odlično preventivno sredstvo,uživamo ga pri prehladih, kašlju, angini in drugih vnetnih procesih, ranah, opeklinah, zvišani temperaturi, glavobolu, izgubi apetita, priporočajo ga pri boleznih želodca, jeter in ledvic, spodbuja metabolizem in pomirja krče, pospešuje potenje zato ni primeren za srčne bolnike.',
    cetrtiOdstavek: 'Cvetlični med - je znano naravno zdravilo proti alergijam (posebno proti senenemu nahodu) in pri izčrpanosti organizma, blagodejno vpliva na srce in ožilje.',
    petiOdstavek: 'Gozdni med - najboljša vrsta medu za okrepitev imunskega sistema in odpornosti organizma, zdravilen pri vnetjih dihalnih poti in živčni napetosti, priporočajo ga slabokrvnim ljudem.',
    sestiOdstavek: 'Kostanjev med - pomaga pri bronhitisu, astmi in drugih dihalnih boleznih in pri žilnih boleznih, saj vpliva na krvni obtok, spodbuja apetit, pomaga pri boleznih prebavil, želodca, jeter, žolča, prostate in slabokrvnosti; zaradi večjega deleža fruktoze kot glukoze, ga lahko pod zdravniškim nadzorom uporabljajo lažji sladkorni bolniki.',
    sedmiOdstavek: 'Akacijev med - ureja prebavo, zmanjšuje izločanje želodčne kisline, regulira funkcijo želodca, pomaga pri zaustavljanju krvavitev, priporoča se za pomirjanje v stresnih situacijah in pri nespečnosti ter prehladu – posebno učinkovit je pri majhnih otrocih; zaradi večjega deleža fruktoze kot glukoze, ga lahko pod zdravniškim nadzorom uporabljajo lažji sladkorni bolniki.',
    slika: require('../images/tomaz_gregorc.jpg'),
};

export const blog2 = {
    okvirno: 'Hranilna sestava medu je močno odvisna od vrste medu. Med je sestavljen iz naravnih sladkorjev (77%), vode (17%) in iz preostalih snovi (5%).  Med sladkorji prevladujeta glukoza in fruktoza.',

    naslov1: 'IZ ČESA JE MED?',
    prviOdstavek: 'Hranilna sestava medu je močno odvisna od vrste medu. Med je sestavljen iz naravnih sladkorjev (77%), vode (17%) in iz preostalih snovi (5%).  Med sladkorji prevladujeta glukoza in fruktoza. Ti sladkorji so hitro prebavljivi, prav tako imajo nizek glikemični indeks in torej niso obremenjujoči za trebušno slinavko. Zaradi visoke vsebnosti sladkorja, je med energijsko bogato živilo s približno 300 kcal na 100 g. Glikemični indeksi se zaradi variabilne vsebnosti fruktoze v različnih vrstah medu zelo razlikujejo in znašajo vse od 35 pa do 85. Med je bogat z minerali – s kalcijem, železom, bakrom, magnezijem, cinkom, fosforjem, manganom, itd. Največ mineralov v sebi skrivajo gozdne vrste medu, pri čemer je na prvem mestu po vsebnosti mineralnih snovi kostanjev med. ',
    drugiOdstavek: 'V medu se nahajajo tudi pomembni encimi in vitamini (vitamini skupin B, C, folna kislina), prav tako pa med v sebi nima škodljivega holesterola. Če izberete temnejši med, to pomeni, da je ta med bogatejši z antioksidanti in aminokislinami, ki na naše telo delujejo protimikrobno in tudi protivnetno. Analize kažejo, da med vsebuje prek 300 aromatičnih snovi, več kot 200 je že prepoznanih. Najbolj značilno aromo medu dajeta spojini β-damaskenon in fenil-acetaldehid. ',
    tretjiOdstavek: 'Vrsto medu določamo glede na to, katero medičino čebele uporabijo. V medu lahko najdemo samo eno vrsto medičine ali pa več vrst medenega nektarja, pri čemer govorimo o mešanih vrstah. Najbolj znane »čiste« vrste medu so: lipov, akacijev, hojev, smrekov, kostanjev, žajbljev, ajdin … Med mešanimi vrstami poznamo npr. cvetlični med in gozdni med. Na podlagi aminokislinske sestave je možno določiti geografsko poreklo medu.',
    slika: require('../images/blog2.jpg'),
};

export const blog3 = {
    okvirno: 'Ali ste vedeli, da čebele ne prepoznajo rdeče barve, kljub temu da imajo pet očes. Zunanja stranska robova pokriva par sestavljenih oči, ki sta sestavljena iz velikega števila omatidijev. ',
    
    naslov1: 'ČEBELE NE VIDIJO RDEČE BARVE',
    prviOdstavek: 'Ali ste vedeli, da čebele ne prepoznajo rdeče barve, kljub temu da imajo pet očes. Zunanja stranska robova pokriva par sestavljenih oči, ki sta sestavljena iz velikega števila omatidijev. Naloga sestavljenih očesc je prepoznavanje vzorcev, dobro zaznavajo premikanje, dlake v očesu omogočajo zaznavanje zračnega toka. To je pomembno v primeru vetra, da lahko uravnava hitrost leta v primerjavi z vetrom.  Globinsko ostrino imajo  čebele slabo, zaznavajo kratkovalovno svetlobo. Na zgornjem robu glave so tri pikčasta očesca, ki so sestavljena iz leče. Ta je prosojna in prepušča svetlobne žarke do vidnih celic. Pikčaste oči zaznavajo intenzivnost svetlobe, polarizacijske svetlobe, njihova naloga je uravnavanje dnevnih aktivnosti čebele in orientacija.',
    drugiOdstavek: 'Mrežasto oko je sestavljeno iz številnih samostojnih očesc. Vidna območja se delno prekrivajo, tako da čebele vidijo prostorsko. Če imajo očesa čebel dovolj svetlobe, omogočajo čebelam gledanje tudi na zelo kratke razdalje. Strokovnjaki si niso edini, katere barve lahko vidijo. A strinjajo se, da lahko vidijo spekter med modro in zeleno nekaj oranžne in rumene. To je svetlobni spekter od 300-650 nanometrov – ljudje vidimo valovne dolžine od 400-800 nanometrov – in popolnoma izključuje rdečo barvo. ',
    tretjiOdstavek: 'Čebele rdečo najbrž vidijo črno. Zato pa lahko vidijo ultravijolično svetlobo, katere ljudje ne vidimo. S tem spektrom si čebele pomagajo pri iskanju cvetov, kjer je ta spekter najbolj gost. Če ultravijolične svetlobe primanjkuje, čebele izgubijo zanimanje za nabiranje, razen če so v to prisiljene zaradi lakote.',
    slika: require('../images/blog3.png'),
};

export const blog4 = {
    okvirno: 'Poslikava panjskih končnic - prednjih deščic nekdanjih panjev »kranjičev« - je posebnostljudske kulture slovenskega alpskega sveta.',

    naslov1: 'POSLIKAVA PANJSKIH KONČNIC',
    prviOdstavek: 'Poslikava panjskih končnic - prednjih deščic nekdanjih panjev »kranjičev« - je posebnost ljudske kulture slovenskega alpskega sveta. Vzniknila je v 18. st. in imela svojo zlato dobo od leta 1820  - 1880, ko je cvetela kmečka rokodelska obrt, od slikanja pohištva do upodabljanja na steklo. Poslikane panjske končnice so del slovenske ljudske zgodovine in jasen pokazatelj ustvarjalnosti, ki je značilna za slovenski narod.',
    drugiOdstavek: 'Avtorji poslikav na končnicah so večinoma anonimni, saj niso bile slikane zaradi umetnosti, ustvarjene so bile po okusu in želji naročnikov - čebelarjev. Poslikava panjskih končnic se je porodila sama od sebe iz ljudske zavesti. Domišljija je dopuščala vse in pri poslikavah so popustile zavore ustvarjalnosti, ki se je skozi motive, barve in vzorce krepila. Vsebina poslikav je bila v preteklosti največkrat čebelarska, nabožna, mnogokrat poučna, tudi humorna in včasih celo satirična. V približno 150 letih je nastalo preko 50.000 panjskih končnic, ki so jih čebelarji, pogosto za srečo, nameščali na svoje panje. Njihov glavni namen je bil panje ločiti med seboj, nekateri pa so v njih videli izvrstno dodano vrednost čebelarjenju. Vsekakor je marsikatera panjska končnica na panju pristala tudi zato, ker je v tistih časih veljajo, da prav panjske končnice, predvsem tiste s svetniškimi motivi, varujejo čebele v panju.  ', 
    tretjiOdstavek: 'V Čebelarskem muzeju v Radovljici je ohranjena najstarejša do zdaj znana poslikana panjska končnica z motivom Marije z detetom, ki nosi letnico 1758. Med večinskimi religioznimi motivi na končnicah iz 18. st. je v muzeju tudi povsem posvetni prizor boja med Pegamom in Lambergarjem. To je zgodba, v kateri junak z gradu Kamen v bližini Radovljice, Krištof Lambergar, na prošnjo cesarja na Dunaju premaga grozečega Pegama. Danes panjske končnice s svojimi motivi iz preteklosti nosijo veliko zgodovinsko in kulturno vrednost. Imajo izjemen simbolni pomen, reprodukcije najbolj znanih motivov preteklosti pa predstavljajo neprecenljivo etnografsko zakladnico Slovenije.',
    slika: require('../images/blog4.jpg'),
    
};

export const blog5 = {
    okvirno: 'Če ste kdaj mislili, da ste postali tarča čebele, ki vam neumorno sledi, imate najverjetneje prav, saj je kar nekaj znanstvenih raziskav dokazalo, da čebele lahko prepoznajo tudi človeške obraze.',
    
    naslov1: 'ALI STE VEDELI, DA ČEBELE PREPOZNAJO OBRAZE?',
    prviOdstavek: 'Če ste kdaj mislili, da ste postali tarča čebele, ki vam neumorno sledi, imate najverjetneje prav, saj je kar nekaj znanstvenih raziskav dokazalo, da čebele lahko prepoznajo tudi človeške obraze. Še bolj zanimivo pa je, da pri tem uporabljajo enake metode kot ljudje. Čebele prepoznajo skupine črt in vzorcev, v procesu imenovanem konfiguralno procesiranje. Ob tem pa si lahko določen vzorec tudi zapomnijo za kasneje. Ko so čebelam pokazali hrano povezano s slikami obrazov in posodami vode povezanimi z neobraznimi elementi, so biologi bili priča prepoznavanju obrazov z visoko učinkovitostjo. Čez čas so se bitja naučila prepoznati preproste pikčaste in črtaste obraze ter celo bolj fotografske obraze.',
    drugiOdstavek: 'Sposobnost tovrstnega prepoznavanja je še boljša, če so čebele za to nagrajene. To je ugotovil Adrian Dyerz Univerze Monash v Avstraliji, ko je pred leti opravil poskus, v katerem so morale čebele prileteti na točno določene fotografijo človeškega obraza. Če je čebela uspešno opravila nalogo, je za nagrado dobila sladko tekočino.',
    tretjiOdstavek: 'Znanstvenika z Univerze Toulouse Martina Giurfo pa je zanimalo, kakšno tehniko uporabljajo pri tem. Obrazne poteze ljudi je le nakazal (dve piki namesto oči, navpična črta za nos in vodoravna za ustnice), vendar so se čebele naučile tudi tako stiliziran obraz ločiti od drugih črt.',
    cetrtiOdstavek: 'Raziskovalci pod vodstvom Aurore Avargues-Weber z univerze v Toulouseu pa so izvedli štiri vizualne poskuse s čebelami in navadnimi ose. Prej so bile živali usposobljene za prepoznavanje obrazov: pokazali so jim pozitiven dražljaj in negativen dražljaj. Pred slikami so bile majhne ploščadi z nektarjem (za "dober" obraz) ali z brez okusa (za "slab" obraz). Prvi preizkus je popolnoma ponovil trening: žuželke so morale sedeti na ploščadi pred podobo osebe, kjer jih čaka bodisi priboljšek v primeru pravilnega odgovora, bodisi brez okusa v primeru napake. V drugem testu, imenovanem "notranji test", so žuželke po predhodnem treningu pokazale obraz "dobre" ali "slabe" osebe izven konteksta - raziskovalci so slikali po laseh, ušesih in drugih "namigah". Živali so morale z nagrado izbrati pravo spodbudo. V tretjem testu so raziskovalci pokazali obraz "slabe" osebe, ki je imela iste lase, ušesa, vrat in oval kot "dobra" oseba, v četrtem pa je bila naloga obrnjena in zdaj so bili lasje, vrat in druge značilnosti "slabe" osebe. Rezultati so pokazali, da so prvi test opravile čebele z natančnostjo približno 86,3 odstotka, osi pa - 77,9 odstotka. To dokazuje, da se žuželke lahko naučijo razlikovati obraze.',
    slika: require('../images/blog5.jpg'),
};

export const blog6 = {
    okvirno: 'Avstralski in francoski znanstveniki so ugotovili, da se lahko čebele naučijo seštevati in odštevati. Čebele so učili računati tako, da so jih spustili v labirint v obliki črke Y. V prvem prostoru so videle enega do pet likov. ',

    naslov1: 'ČEBELE SE LAHKO NAUČIJO RAČUNATI',
    prviOdstavek: 'Avstralski in francoski znanstveniki so ugotovili, da se lahko čebele naučijo seštevati in odštevati. Čebele so učili računati tako, da so jih spustili v labirint v obliki črke Y. V prvem prostoru so videle enega do pet likov. Če so bili ti modre barve, so morale prišteti, če so bili rumeni, pa odšteti. V naslednjem prostoru je bila na eni strani napačna rešitev, na drugi pa pravilna. Na pravilni strani je bilo število likov za eno večje ali za eno manjše. Če so se odločile pravilno, jih je na koncu labirinta za nagrado čakala sladka voda, v primeru napačne odločitve pa so dobile grenko raztopino kinina, kar jim je pomagalo pri učenju. Tako so se po približno 100 poskusih, ki so skupaj trajali od štiri do sedem ur, naučile, da modra barva pomeni +1, rumena pa -1. Pravilo so znale uporabiti tudi pri drugih številih, piše na spletni strani univerze RMIT v avstralskem Melbournu.',
    drugiOdstavek: 'Študija je tako pokazala, da se lahko čebele nauči, da prepoznajo barve kot simbol za seštevanje in odštevanje in da te informacije uporabijo za reševanje aritmetičnih problemov. Že v preteklih študijah so sicer ugotovili, da čebele razumejo koncept števila nič.',
    slika: require('../images/blog6.jpg'),
};


