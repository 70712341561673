import React, { useRef } from 'react'
import {ReactTitle} from 'react-meta-tags';
import { ExternalLink } from 'react-external-link';
// import emailjs from '@emailjs/browser';


export const Kontakt = () => {
    const form = useRef();
  
    // const sendEmail = (e) => {
    //   e.preventDefault();
  
    //   emailjs.sendForm('CDR-mail', 'template_hcpbf5m', form.current, '-QHe5ceF5SGnMuWlH')
    //     .then((result) => {
    //         console.log(result.text);
    //     }, (error) => {
    //         console.log(error.text);
    //     });
    // };
  
    return (
            <div id='kontakt'>
                <ReactTitle title="ČDRadovljica | kontakt"/>
                <div className='bg-kontkat center'>
                    {/* <div className='bgb-kontakt'></div> */}
                    <div className='kontakt-box'>
                        <div className='dve'>
                            <div className='jaa'>
                                <div className='kontakt-info'>
                                    <h2>Kontaktni podatki</h2>
                                    <h4><i class="fas fa-envelope"></i> E-mail: cebelarsko.drustvo.radovljica@gmail.com</h4>
                                    <h4><i class="fas fa-phone-alt"></i> 041 521 724</h4>
                                    <h4><i class="fas fa-map-marker-alt"></i> Občina Radovljica</h4>
                                    <ExternalLink href='https://www.facebook.com/%C4%8Cebelarsko-dru%C5%A1tvo-Radovljica-112122538037995'> <h3 className='white12'><i class="fab fa-facebook"></i></h3></ExternalLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
  };


export default Kontakt
